import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String,
    stats: Object,
    warning: Object,
  };

  static targets = [
    /* Badges */
    'documents',
    'bulletins',
    'policies',
    'policiesOverdue',
    'riskAssessments',
    'riskAssessmentsOverdue',
    'forms',
    'responses',
    'helpdesk',
    'incidents',
    'incidentsActions',
    'formsActions',
    'riskRecordsActions',
    'featureAnnouncements',
    'assignedCourses',

    /* Warning Badges */
    'formReviewWarning',
    'formIncompleteWarning',

    /* Banners */
    'policyReviewBanner',
    'riskAssessmentReviewBanner',
    'riskRecordReviewBanner',
    'formReviewBanner',
    'incidentUnresolvedBanner'
  ];

  connect() {
    this.load();
  }

  load() {
    fetch(this.urlValue)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response)
        }
        return response.json();
      })
      .then((data) => {
        console.log(data)
        this.processStats(data)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  processStats(data) {
    this.stats = data;

    // Number badges
    this.updateTargets(this.documentsTargets, this.stats.documents);
    this.updateTargets(this.helpdeskTargets, this.stats.helpdesk);
    this.updateTargets(this.formsTargets, this.stats.forms);
    this.updateTargets(this.responsesTargets, this.stats.forms_all_unreviewed);
    this.updateTargets(this.bulletinsTargets, this.stats.bulletins);
    this.updateTargets(this.policiesTargets, this.stats.policies);
    this.updateTargets(this.policiesOverdueTargets, this.stats.policies_overdue);
    this.updateTargets(this.riskAssessmentsTargets, this.stats.risk_assessments);
    this.updateTargets(this.riskAssessmentsOverdueTargets, this.stats.risk_assessments_overdue);
    this.updateTargets(this.incidentsTargets, this.stats.incidents);
    this.updateTargets(this.incidentsActionsTargets, this.stats.incidents_actions);
    this.updateTargets(this.formsActionsTargets, this.stats.forms_actions);
    this.updateTargets(this.riskRecordsActionsTargets, this.stats.risk_records_actions);
    this.updateTargets(this.featureAnnouncementsTargets, this.stats.feature_announcements);
    this.updateTargets(this.assignedCoursesTargets, this.stats.assigned_courses);

    // Warning badges
    this.updateWarningTargets(this.formReviewWarningTargets, this.stats.forms_all_unreviewed);
    this.updateWarningTargets(this.formIncompleteWarningTargets, this.stats.forms_incomplete);

    // Banners
    this.updateBannerTargets(this.policyReviewBannerTargets, [this.stats.policies_overdue])
    this.updateBannerTargets(this.riskAssessmentReviewBannerTargets, [this.stats.risk_assessments_overdue])
    this.updateBannerTargets(this.riskRecordReviewBannerTargets, [this.stats.risk_records_overdue])
    this.updateBannerTargets(this.formReviewBannerTargets, [this.stats.forms_all_unreviewed, this.stats.forms_failures]);
    this.updateBannerTargets(this.incidentUnresolvedBannerTargets, [this.stats.incidents]);
  }

  updateTargets(targets, count) {
    if (count > 0) {
      targets.forEach((target) => {
        target.innerHTML = count;
        target.style.display = null;
        if (count > 99) {
          target.classList.add('unread-count--small')
        }
      });
    }
  }

  updateWarningTargets(targets, count) {
    if (count > 0) {
      targets.forEach((target) => {
        target.innerHTML = '<b>!</b>';
        target.style.display = null;
      });
    }
  }

  // Try to fill .placeholder elements with values in the order they
  // appear in the template.
  updateBannerTargets(targets, counts) {
    targets.forEach((target) => {
      const placeholders = target.querySelectorAll('.placeholder');

      counts
        .map((count, index) => {
          return [count, placeholders?.[index]];
        })
        .filter(([count, placeholder]) => {
          return count >= 1 && placeholder;
        })
        .forEach(([count, placeholder]) => {
          placeholder.innerHTML = count;
          target.style.display = null;
        });
    });
  }
}
