import { Controller } from '@hotwired/stimulus'
import { find, forEach, get, includes } from 'lodash-es'

export default class extends Controller {

  static values = { refreshUrl: String }
  static targets = ['frame', 'groupIdsInput', 'locationIdsInput']

  connect() {
    if (this.hasGroupIdsInputTarget) {
      this.groupIdsInputTarget.addEventListener('change', this.refreshSelectedGroups.bind(this))
    }
    if (this.hasLocationIdsInputTarget) {
      this.locationIdsInputTarget.addEventListener('change', this.refreshSelectedGroups.bind(this))
    }
  }

  refreshSelectedGroups(e) {
    if (!this.hasFrameTarget) {
      console.error('no frame target to - unable to refresh selected groups')
    }

    let refreshUrl = new URL(location.origin + this.refreshUrlValue)
    let refreshParams = new URLSearchParams(refreshUrl.search)
    refreshParams.set('group_ids', JSON.stringify(this.selectedGroupIds()))
    refreshParams.set('location_ids', JSON.stringify(this.selectedLocationIds()))
    refreshUrl.search = refreshParams
    this.frameTarget.setAttribute('src', refreshUrl.toString())
  }

  selectedGroupIds() {
    return this.processSelectValues(this.groupIdsInputTarget)
  }

  selectedLocationIds() {
    if (this.hasLocationIdsInputTarget) {
      return this.processSelectValues(this.locationIdsInputTarget)
    } else {
      return []
    }
  }

  processSelectValues(select) {
    const selectAllOpt = select.querySelector('option:checked[data-select-all=true]')
    let selectAll = false
    if (selectAllOpt) {
      selectAll = true
    }

    const selected = []
    const options = get(select, 'options', [])

    forEach(options, function (opt) {
      if ((opt.selected || selectAll) && opt.value != '') {
        selected.push(opt.value)
        if (selectAll) {
          opt.selected = false
        }
      }
    })

    return selected
  }

  removeGroup(e) {
    const groupId = get(e, 'currentTarget.dataset.value')
    const options = get(this.groupIdsInputTarget, 'options', [])
    const selectedOption = find(options, { value: groupId })
    if (selectedOption) {
      selectedOption.selected = false
    }
    return this.refreshSelectedGroups()
  }

  toggleGroup(e) {
    e.preventDefault()
    // Check if toggling root input (ie 'All courses') or input associated with an item id (course, category, group)
    const input = e.currentTarget.querySelector('input')
    input.checked = !input.checked

    this.refreshSelectedGroups()
  }

}