import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['completed', 'completionLink']

  static values = {
    completed: {type: Boolean, default: false},
  }

  connect() {
    if (this.hasCompletionLinkTarget) {
      this.completionLinkTarget.setAttribute('disabled', true)
    }
  }

  completedTargetConnected() {
    if (this.hasCompletionLinkTarget) {
      console.log('connected');
      this.completionLinkTarget.removeAttribute('disabled')
      this.completionLinkTarget.classList.remove('hide')
      console.log(this.completedValue);
      if (this.completedValue == false) {
        this.exitFullScreen()
      }
    }
  }

  isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  isVideoInFullscreen() {
    if (document.fullscreenElement?.nodeName === "VIDEO") {
      return 'true';
    }
    return 'false';
  }
  
  exitFullScreen() {
    console.log('exit full');
    // exit fullscreen 5 seconds after if mobile phone and in full screen
    if (this.isMobileDevice() && this.isVideoInFullscreen()) {
      setTimeout(() => {
        console.log('exit');
        this.completedValue = true
        document.exitFullscreen()
      }, 5000);
    }
  }
}
