import { Controller } from "stimulus"
import Glide from '@glidejs/glide'

export default class extends Controller {
  static values = {
    type: String,
    slidesPerView: { type: Number, default: 4 }
  }

  connect() {
    const glideElement = this.element.querySelector('.glide')
    const slides = this.element.querySelectorAll('.glide__slide')

    if (glideElement && slides.length > 0) {
      const config = {
        type: "slider",
        keyboard: false,
        autoplay: 8000,
        perView: this.slidesPerViewValue,
        breakpoints: {
          1000: {
            perView: (this.slidesPerViewValue < 3 ? this.slidesPerViewValue : 3)
          },
          800: {
            perView: (this.slidesPerViewValue < 2 ? this.slidesPerViewValue : 2)
          },
          480: {
            perView: 1
          }
        }
      }

      const glide = new Glide(glideElement, config)

      // Function to toggle arrows based on number of slides and current perView
      const toggleArrows = () => {
        const perView = glide.settings.perView
        const controls = glideElement.querySelector('.glide__arrows')

        if (slides.length <= perView) {
          controls.style.display = 'none'
        } else {
          controls.style.display = 'flex'
        }
      }

      glide.on(['mount.after', 'resize'], toggleArrows)

      glide.mount()
    }
  }
}
