import { Controller } from "stimulus"
import { debounce, includes } from "lodash"

export default class extends Controller {
  static targets = ["field", "results", "selectedList", "selectedHeader", "hiddenField"]
  static values = { autocompleteUrl: String }

  connect() {
    this.selectedItems = []
    this.highlightedIndex = -1
    this.resultsTarget.style.display = 'none'
    this.selectedHeaderTarget.style.display = 'none'
    this.debouncedFetchResults = debounce(this.fetchResults.bind(this), 300)
    this.fieldTarget.addEventListener('input', this.onInput.bind(this))
    this.fieldTarget.addEventListener('keydown', this.onKeyDown.bind(this))
    this.populateExistingSelections()
  }

  populateExistingSelections() {
    const cachedItems = this.hiddenFieldTarget.value
    if (cachedItems) {
      const items = JSON.parse(cachedItems)
      items.forEach(item => {
        this.addItem(item)
      })
      this.hiddenFieldTarget.value = ''
    }
  }

  onInput(event) {
    const term = event.target.value
    if (term.length > 0) {
      this.debouncedFetchResults(term)
    } else {
      this.clearResults()
    }
  }

  fetchResults(term) {
    const separator = this.autocompleteUrlValue.includes('?') ? '&' : '?'
    fetch(`${this.autocompleteUrlValue}${separator}q=${term}`)
      .then(response => response.json())
      .then(data => this.showResults(data))
  }

  onKeyDown(event) {
    if (event.key === 'ArrowDown') {
      this.highlightNext()
    } else if (event.key === 'ArrowUp') {
      this.highlightPrevious()
    } else if (event.key === 'Enter') {
      event.preventDefault()
      this.selectHighlighted()
    }
  }

  showResults(items) {
    this.clearResults()
    this.positionResults()

    this.highlightedItems = []
    items.forEach((item, index) => {
      const listItem = document.createElement('li')
      const link = document.createElement('a')
      link.tabIndex = -1
      link.dataset.index = index
      link.innerHTML = `${this.getItemIcon(item.type)} ${item.label}`
      if (item.type !== 'Error' && item.type !== 'Nothing') {
        link.addEventListener('click', () => this.addItem(item))
        link.addEventListener('mouseover', () => this.highlightItem(index))
      }
      listItem.appendChild(link)
      this.resultsTarget.appendChild(listItem)
      this.highlightedItems[index] = item
    })
  }

  positionResults() {
    this.resultsTarget.style.display = 'block'
    this.resultsTarget.style.top = `${this.fieldTarget.offsetTop + this.fieldTarget.offsetHeight}px`
    this.resultsTarget.style.left = `${this.fieldTarget.offsetLeft}px`
    this.resultsTarget.style.width = `${this.fieldTarget.offsetWidth}px`
  }

  clearResults() {
    this.resultsTarget.innerHTML = ''
    this.resultsTarget.style.display = 'none'
    this.highlightedIndex = -1
  }

  clearErrors() {
    const parentDiv = this.fieldTarget.parentElement
    parentDiv.classList.remove('error')
    const errorDiv = parentDiv.querySelector('small.error')
    if (errorDiv) {
      errorDiv.remove()
    }
  }

  highlightNext() {
    if (this.highlightedIndex < this.resultsTarget.children.length - 1) {
      this.highlightItem(this.highlightedIndex + 1)
    }
  }

  highlightPrevious() {
    if (this.highlightedIndex > 0) {
      this.highlightItem(this.highlightedIndex - 1)
    }
  }

  highlightItem(index) {
    if (this.highlightedIndex >= 0) {
      this.resultsTarget.children[this.highlightedIndex].classList.remove('highlighted')
    }
    this.highlightedIndex = index
    this.resultsTarget.children[this.highlightedIndex].classList.add('highlighted')
  }

  selectHighlighted() {
    if (this.highlightedIndex >= 0) {
      const itemIndex = this.resultsTarget.children[this.highlightedIndex].querySelector('a').dataset.index
      const item = this.highlightedItems[itemIndex]
      this.addItem(item)
    }
  }

  addItem(item) {
    if (includes(this.selectedItems, item.value)) {
      console.log(`selected items already includes ${item.value}`)
      // TODO highlight animation on the selected entry
    } else {
      const recipientDiv = document.createElement('div')
      recipientDiv.classList.add('recipient', item.type.toLowerCase())
      recipientDiv.innerHTML = `
        ${item.label}
        <input type="hidden" name="${this.hiddenFieldTarget.name}" value="${item.value}">
        <i class="fa fa-trash" data-action="click->components--recipients-selector#removeItem" data-value="${item.value}"></i>
      `
      this.selectedListTarget.appendChild(recipientDiv)
      this.selectedItems.push(item.value)
    }
    this.clearResults()
    this.fieldTarget.value = ''
    this.fieldTarget.focus()
    this.clearErrors()

    this.updateSelectedHeader()
  }

  removeItem(event) {
    const value = event.target.dataset.value
    this.selectedItems = this.selectedItems.filter(item => item !== value)
    event.target.closest('.recipient').remove()
    this.updateSelectedHeader()
  }

  updateSelectedHeader() {
    if (this.selectedItems.length > 0) {
      this.selectedHeaderTarget.style.display = 'block'
    } else {
      this.selectedHeaderTarget.style.display = 'none'
    }
  }

  getItemIcon(type) {
    switch (type) {
      case 'User':
        return '<i class="fa fa-user fa-fw"></i> '
      case 'Group':
        return '<i class="fa fa-users fa-fw"></i> '
      case 'Error':
        return '<i class="fa fa-exclamation-triangle fa-fw"></i> '
      case 'Nothing':
        return '<i class="fa fa-minus-circle fa-fw"></i> '
      default:
        return ''
    }
  }
}
