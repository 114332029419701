import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  removeItem(e) {
    const id = e.currentTarget.dataset.id
    const el = document.getElementById(id);
    
    el.checked = false
    e.currentTarget.remove();

    this.checkForNoActiveCheckboxes(el)
    
    this.submitForm()
  }

  checkForNoActiveCheckboxes(el) {
    const filterEl = el.closest(".filter")
    const inputs = filterEl.querySelectorAll('[data-training--reporting-filter-target="input"]');
    let isChecked = false;
    
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].checked) {
        isChecked = true;
        break;
      }
    }
    
    if (!isChecked) {
      const rootInput = filterEl.querySelector('[data-training--reporting-filter-target="rootInput"]');
      rootInput.checked = true
    }
  }

  submitForm() {
    document.getElementById("filter-form").requestSubmit();
  }
}
