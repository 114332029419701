import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'rootInput'];

  connect() {
    // Create pills for pre-checked inputs
    if (this.hasInputTarget && this.checkForNoActiveCheckboxes()) {
      this.inputTargets.forEach((input) => {
        if (input.checked) {
          this.createPillElement(input.getAttribute("data-name"), input.id, input.getAttribute("data-type"))
        }
      });
    }
  }

  toggleItem(e) {
    e.preventDefault();
    // Check if toggling root input (ie "All courses") or input associated with an item id (course, category, group)
    const input = e.currentTarget.querySelector("input");
    if (e.currentTarget.querySelector("input") == this.rootInputTarget) {
      // If root input is uncheck -> check root input and uncheck all other inputs
      if (!input.checked) {
        input.checked = !input.checked;
        this.inputTargets.forEach(item => {
          item.checked = false
        });
      }

      this.resetFilterGroup(input)

    } else {
      input.checked = !input.checked;
      
      // Uncheck root input if checked
      if (this.rootInputTarget.checked) {
        this.rootInputTarget.checked = false
      } else {
        this.checkForNoActiveCheckboxes();
      }
      
      this.checkForPillExistance(input)
    }

    this.submitForm();
  }

  resetFilterGroup(input) {
    const type = input.getAttribute("data-type")
    const activePills = document.querySelectorAll(`.insights-dataset__pill[data-type='${type}']`)
    
    if (activePills) {
      activePills.forEach(pill => {pill.click()});
    }
  }

  checkForPillExistance(input) {
    const pill = document.querySelector(`[data-id=${input.id}]`)
    if (input.checked === true && !pill) {
      this.createPillElement(input.getAttribute("data-name"), input.id, input.getAttribute("data-type"))
    } else {
      pill.remove();
    }
  }

  createPillElement(name, id, type){
    const parentDiv = document.querySelector('.insights-dataset__pills');
    
    const pillDiv = document.createElement('div');
    pillDiv.className = 'insights-dataset__pill';
    pillDiv.setAttribute('data-action', 'click->training--reporting-selected-filters#removeItem');
    pillDiv.setAttribute('data-id', id);
    pillDiv.setAttribute('data-type', type);

    const titleDiv = document.createElement('div');

    titleDiv.className = 'insights-dataset__pill-title';
    titleDiv.textContent = name;

    const icon = document.createElement('i');
    icon.className = 'fa fa-times';

    pillDiv.appendChild(titleDiv);
    pillDiv.appendChild(icon);

    parentDiv.appendChild(pillDiv);
  }

  
  // if no inputs are checked, check the root input
  checkForNoActiveCheckboxes() {
    let isChecked = false;

    for (let i = 0; i < this.inputTargets.length; i++) {
      if (this.inputTargets[i].checked) {
        isChecked = true;
        break;
      }
    }

    if (!isChecked) {
      this.rootInputTarget.checked = true
    }

    return isChecked
  }


  submitForm() {
    document.getElementById("filter-form").requestSubmit();
  }
}
