import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["format", "form", "toggle", "cardToggle", "listToggle"]
  
  connect () {
    this.setActiveFormat();
  }

  toggle(e) {
    const clickedElement = e.target;
  
    if (!clickedElement.classList.contains("active")) {
      this.toggleTargets.forEach(element => element.classList.remove("active"));
  
      clickedElement.classList.add("active");
  
      if (clickedElement.classList.contains("fa-th")) {
        this.formatTarget.value = "card";
      } else if (clickedElement.classList.contains("fa-list")) {
        this.formatTarget.value = "list";
      }
  
      this.formTarget.requestSubmit();
    }
  }

  setActiveFormat() {
    const url = new URL(window.location.href);
    const formatParam = url.searchParams.get("format");

    if (formatParam == "list") {
      this.listToggleTarget.classList.add("active")
    } else {
      this.cardToggleTarget.classList.add("active")
    }
  }

}
